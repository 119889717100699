<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="option_txt">지급 현황</h5>
        <div>
          <span class="option_txt">조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="input_search">
            <input
              type="text"
              placeholder="구매처 검색"
              :value="search_value"
              @input="typing($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div>
          <span class="option_txt">지급 구분</span>

          <div class="input_text">
            <my-local-selectric
              v-if="showSelectric"
              :id="'payment_selectric'"
              :watch="search_type"
              :options="payment_options"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filtered_payments.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <colgroup>
            <col v-for="(n, index) in managementMode ? 5 : 4" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>지급일</th>
              <th>구매처명</th>
              <th>지급액</th>
              <th>지급구분</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(payment, index) in filtered_payments"
              :key="payment.id"
              @click="selectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>{{ payment.input_date }}</td>
              <td class="text_left">
                {{ findInfoFromId(companys, payment.company_id).name }}
              </td>
              <td class="text_right">
                &#8361; {{ mx_makeComma(payment.total_value) }}
              </td>
              <td>
                {{
                  findInfoFromId(payment_types, payment.payment_type_id).name
                }}
              </td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal()">
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label class="require">구매처명</label>
            <div class="input_search">
              <input
                type="text"
                placeholder="구매처 검색"
                :readonly="managementData.company_id != null"
                :value="
                  findInfoFromId(companys, managementData.company_id).name
                "
                :disabled="!managementMode || selectedIndex == -1"
              />
              <button v-show="managementMode && selectedIndex != -1">
                <i class="fa fa-search" @click="showCompanySearch = true"></i>
              </button>
            </div>
          </div>
          <div class="input_text">
            <label>지급일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              v-model="managementData.input_date"
              :disabled="!managementMode || selectedIndex == -1"
              :readonly="!managementMode || selectedIndex == -1"
            />
          </div>
          <div class="input_text">
            <label>지급액</label>
            <input
              type="text"
              placeholder=""
              :value="$makeComma(managementData.total_value)"
              inputmode="decimal"
              @input="$inputNumberInt($event, managementData, 'total_value')"
              :disabled="!managementMode || selectedIndex == -1"
            />
          </div>
          <div class="input_text">
            <label>지급구분</label>
            <div>
              <my-selectric
                v-if="showSelectric"
                :id="'payment_selectric'"
                :watch="managementData.payment_type_id"
                :options="payment_options"
                :commit="'setManagementDataPaymentTypeIdToPayment'"
                :class="managementMode && selectedIndex != -1 ? '' : 'disabled'"
              >
              </my-selectric>
            </div>
          </div>
          <div class="btn_wrap">
            <button
              v-show="managementMode && selectedIndex != -1"
              type="button"
              class="btn_sub2"
              :disabled="isValidModify"
              @click="submitForm()"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="deletePayment()"
    ></two-button-modal>
  </div>
</template>

<script>
import MySelectric from '@/layouts/components/MySelectric';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import FavoriteMixin from '@/mixins/favorite';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { yyyymmdd } from '@/utils/func';
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import price from '@/mixins/price';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';

export default {
  mixins: [ModalMixin, fetchMixin, SpinnerMixin, FavoriteMixin, price],
  data() {
    return {
      search_value: '',
      showSelectric: false,
      showCompanySearch: false,

      //delete modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  components: {
    MySelectric,
    TwoButtonModal,
    CompanySearch,
    MyLocalSelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromPaymentPage',
      managementData: 'getManagementDataFromPayment',
      cloneData: 'getCloneDataFromPayment',
      payment_options: 'getPaymentsForSelectricAll',
      search_type: 'getSearchTypeFromPayment',
      selectedIndex: 'getSelectedIndexFromPayment',
      payments: 'getPayments',
      payment_types: 'getPaymentTypes',
      date: 'getDateFromPayment',
      start_date: 'getStartDateFromPayment',
      end_date: 'getEndDateFromPayment',
      companys: 'getCompany',
    }),
    checkValid() {
      return this.managementData.payment_type_id == null ? false : true;
    },
    checkValidTotalValue() {
      if (
        this.managementData.total_value == undefined ||
        this.managementData.total_value == null ||
        String(this.managementData.total_value).trim() == '0' ||
        String(this.managementData.total_value).trim() == ''
      ) {
        return false;
      } else return true;
    },
    filtered_payments() {
      const Hangul = require('hangul-js');
      return this.payments
        .filter(
          x =>
            x.payment_type_id == this.search_type || this.search_type == null,
        )
        .filter(
          x =>
            this.findInfoFromId(this.companys, x.company_id).name.includes(
              this.search_value,
            ) ||
            Hangul.d(
              this.findInfoFromId(this.companys, x.company_id).name,
              true,
            )
              .map(x => x[0])
              .join('')
              .includes(this.search_value),
        );
    },
    isValidModify() {
      if (this.managementMode) {
        let modifyData = this.lodash.clonedeep(this.managementData);

        if (modifyData == undefined) return true;

        if (isNaN(modifyData.total_value) == false) {
          modifyData.total_value = this.mx_makeComma(modifyData.total_value);
        }
        modifyData = JSON.stringify(modifyData);

        let originData = this.cloneData;
        if (isNaN(originData.total_value) == false) {
          originData.total_value = this.mx_makeComma(originData.total_value);
        }
        originData = JSON.stringify(originData);

        if (modifyData == originData) {
          return true;
        } else return false;
      } else return false;
    },
  },
  methods: {
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToPayment', e.target.value);
          this.selectRow(-1);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PAYMENT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '지급 리스트',
          ); //날짜
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPayment', yyyymmdd(new Date()));
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToPayment', e.target.value);
          this.selectRow(-1);
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_PAYMENT',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '지급 리스트',
          ); //날짜
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPayment', yyyymmdd(new Date()));
      }
    },
    changeSelectric(type) {
      this.selectRow(-1);
      this.$store.commit('setSearchTypeToPayment', type);
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectRow(-1);
    },
    pushManagementCompany(arg) {
      console.log(arg);
      this.managementData.company_id = arg.id;
      this.showCompanySearch = false;
    },

    selectRow(index) {
      this.$store.commit('setSelectedIndexToPayment', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToPayment',
          this.lodash.clonedeep(this.filtered_payments[index]),
        );
        this.$store.commit(
          'setCloneDataToPayment',
          this.lodash.clonedeep(this.filtered_payments[index]),
        );
      } else {
        this.$store.commit('setManagementDataToPayment', {
          id: -1,
          company_id: null,
          payment_type_id: null,
          total_value: null,
          input_date: '',
        });
      }
    },
    async deletePayment() {
      this.showSpinner();
      await this.$store
        .dispatch('DELETE_PAYMENTS', this.payments[this.selectedIndex].id)
        .then(() => {
          this.$store.commit('setSelectedIndexToPayment', -1);
          this.$store.commit('setManagementDataToPayment', {
            id: -1,
            company_id: null,
            payment_type_id: null,
            total_value: null,
            input_date: '',
          });
          this.FETCH_WITH_PAYLOAD('FETCH_PAYMENT', {
            start: this.start_date,
            end: this.end_date,
          });
        })
        .catch(() => {
          this.openOneButtonModal('삭제 중 오류', '삭제 중 오류 발생');
        })
        .finally(() => {
          this.CloseModal();
          this.hideSpinner();
        });
    },
    async submitForm() {
      if (this.checkValid && this.checkValidTotalValue) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.total_value = this.$makeNumber(payload.total_value);

        this.showSpinner();
        await this.$store
          .dispatch('UPDATE_PAYMENTS', payload)
          .then(() => {
            this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
            this.FETCH_WITH_PAYLOAD(
              'FETCH_PAYMENT',
              {
                start: this.start_date,
                end: this.end_date,
              },
              '지급',
            );
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal('수정 중 오류', '수정 중 오류 발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        if (!this.checkValid) {
          this.openOneButtonModal(
            '수정 불가',
            '지급 구분을 반드시 선택해 주세요.',
          );
        } else if (!this.checkValidTotalValue) {
          this.openOneButtonModal(
            '지급액 수정 오류',
            '지급액은 1원이상 입력해주세요.',
          );
        }
      }
    },
  },
  async created() {
    if (this.start_date == null) {
      console.log('null');
      const today = new Date();
      this.$store.commit('setEndDateToPayment', yyyymmdd(today));
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setStartDateToPayment', yyyymmdd(lastMonth));
    }

    if (this.payment_options.length < 2) {
      await this.FETCH('FETCH_PAYMENTS_TYPE', '지급구분');
    }
    if (this.companys.length == 0) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PAYMENT',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '지급 리스트',
    ); //날짜

    this.showSelectric = true;
  },
};
</script>

<style></style>
