<template>
  <div class="article">
    <div class="aside_management_mode regist_mode">
      <div class="head">
        <h5>지급 등록</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div>
            <div class="input_text">
              <label>구매처</label>
              <div class="input_search">
                <input
                  type="text"
                  placeholder="구매처 검색"
                  :readonly="newData.company_id != null"
                  :value="findInfoFromId(companys, newData.company_id).name"
                />
                <button>
                  <i class="fa fa-search" @click="showCompanySearch = true"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="input_text">
            <label>지급일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              v-model="newData.input_date"
            />
          </div>
          <div class="input_text">
            <label>지급액</label>
            <input
              type="text"
              :value="$makeComma(newData.total_value)"
              inputmode="decimal"
              @input="$inputNumberInt($event, newData, 'total_value')"
            />
          </div>
          <div class="input_text">
            <label>지급구분</label>
            <div>
              <my-selectric
                v-if="showSelectric"
                :id="'payment_selectric'"
                :watch="newData.payment_type_id"
                :options="payment_options"
                :commit="'setNewDataPaymentTypeIdToPayment'"
              >
              </my-selectric>
            </div>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_sub2"
              :disabled="checkValid"
              @click="submitForm()"
            >
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <company-search
      v-if="showCompanySearch"
      :search_type="2"
      @onclose="showCompanySearch = false"
      @onselect="pushNewCompany($event)"
    ></company-search>
  </div>
</template>

<script>
import MySelectric from '@/layouts/components/MySelectric';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import price from '@/mixins/price';
// /import { clone } from '@/utils/func';
export default {
  mixins: [ModalMixin, SpinnerMixin, fetchMixin, price],
  data() {
    return {
      showModal: false,
      showCompanySearch: false,
      showSelectric: false,
    };
  },
  components: {
    CompanySearch,
    MySelectric,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromPaymentPage',
      newData: 'getNewDataFromPayment',
      payment_options: 'getPaymentsForSelectric',
      selectedIndex: 'getSelectedIndexFromPayment',
      companys: 'getCompany',
      start: 'getStartDateFromPayment',
      end: 'getEndDateFromPayment',
    }),
    checkValid() {
      const companyId = this.newData.company_id;
      const totalValue = this.newData.total_value;
      const paymentType = this.newData.payment_type_id;

      const blankChk = [
        { chk: companyId, data: -1 },
        { chk: totalValue, data: null },
        { chk: paymentType, data: null },
      ];

      for (let i = 0; i < blankChk.length; i++) {
        if (blankChk[i].chk == blankChk[i].data) {
          return true;
        }
      }
      return false;
    },
    chkTotalValueZero() {
      if (this.newData.total_value == 0 || this.newData.total_value == '0') {
        return true;
      } else return false;
    },
  },
  methods: {
    pushNewCompany(arg) {
      this.newData.company_id = arg.id;
      this.showCompanySearch = false;
    },
    async submitForm() {
      if (this.chkTotalValueZero) {
        this.openOneButtonModal(
          '지급액 등록 오류',
          '지급액은 1원이상 입력해주세요.',
        );
        return;
      }

      const today = yyyymmdd(new Date());
      let payloadDate = {
        start: this.start,
        end: this.end,
      };

      const data = this.lodash.clonedeep(this.newData);
      data.total_value = this.$makeNumber(data.total_value);

      this.showSpinner();
      await this.$store
        .dispatch('INSERT_PAYMENTS', data)
        .then(() => {
          this.openOneButtonModal('등록 성공', '성공적으로 등록하였습니다.');

          this.FETCH_WITH_PAYLOAD('FETCH_PAYMENT', payloadDate, '지급 리스트');
          this.$store.commit('setNewDataToPayment', {
            company_id: -1,
            payment_type_id: null,
            total_value: 0,
            input_date: today,
          });
        })
        .catch(() => {
          this.openOneButtonModal('등록 실패', '등록에 실패하였습니다.');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },

  async created() {
    if (this.start == null) {
      const today = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      this.$store.commit('setEndDateToPayment', yyyymmdd(today));
      this.$store.commit('setStartDateToPayment', yyyymmdd(lastMonth));
    }
    if (this.newData.input_date == '') {
      this.newData.input_date = yyyymmdd(new Date());
    }
    if (this.payment_options.length < 2) {
      this.FETCH('FETCH_PAYMENTS_TYPE', '지급구분');
    }
    this.showSelectric = true;
  },
};
</script>

<style scoped></style>
